import { Link, RouteComponentProps } from "@reach/router";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import { RootState } from "../core/store";
import { getCurrentEvent } from "../core/store/events/reducers";
import { IEvent } from "../core/store/events/types";
import { getPlayerData } from "../core/store/player/reducers";
import { IPlayer } from "../core/store/player/types";
// Highlight images
import Step1 from "../img/home/step-1@1x.jpg";
import Step1x2 from "../img/home/step-1@2x.jpg";
import Step2 from "../img/home/step-2@1x.jpg";
import Step2x2 from "../img/home/step-2@2x.jpg";
// import pitch from "../img/home/pitch.jpg";
// import pitch2x from "../img/home/pitch@2x.jpg";
import { Main, Wrapper } from "./Layout";
import Login from "./Login";
import Status from "./status/Status";
import TriangleCard from "./TriangleCard";
import Button from "./Button";

const Highlights = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.space[4]} ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    flex-direction: row;
    justify-content: space-between;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    margin-inline-start: ${({ theme }) => theme.space[4]};
    margin-inline-end: ${({ theme }) => theme.space[4]};
  }
`;

//<Button> was overriding 'flex: 1' on the flex items - this corrects that
const HighlightsFlexItem = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    flex: 0 0 calc(50% - 1rem);
  }
`;

const HighlightsItem = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: ${({ theme }) => theme.space[4]};

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    flex: 0 0 calc(50% - 1rem);
    margin-bottom: 0;
  }
`;

interface IPropsFromState {
  now: IEvent | null;
  player: IPlayer | null;
}

type Props = RouteComponentProps & IPropsFromState & WithTranslation;

class Home extends React.Component<Props> {
  public componentDidMount() {
    this.TrackFacebook();
  }

  public TrackFacebook() {
    const fbq = (window as any).fbq;
    if (fbq) {
      fbq("trackCustom", "FPLLanding");
    }
  }

  public render() {
    const { t, now, player } = this.props;

    // Show the status page if have a team and the game has started
    if (now && player && player.entry) {
      return <Status />;
    }

    return (
      <Wrapper>
        <Main isWide={true}>
          <Login />
          <Highlights>
            {player && !player.entry && (
              <>
                <HighlightsFlexItem>
                  <Button to="/squad-selection" variant="home" as={Link}>
                    <HighlightsItem>
                      <TriangleCard
                        title={t("home.highlightsLeftTitle", "Pick your squad")}
                        desc={t(
                          "home.highlightsLeftDesc",
                          "Use your budget of 100M SAR to pick a squad of 15 players from RSL."
                        )}
                        mdSrc={Step1x2}
                        smSrc={Step1}
                      />
                    </HighlightsItem>
                  </Button>
                </HighlightsFlexItem>

                <HighlightsFlexItem>
                  <Button to="/squad-selection" variant="home" as={Link}>
                    <HighlightsItem>
                      <TriangleCard
                        title={t(
                          "home.highlightsRightTitle",
                          "Create & join leagues"
                        )}
                        desc={t(
                          "home.highlightsRightDesc",
                          "Play against friends, family members, colleagues or your friends from the web in private leagues."
                        )}
                        mdSrc={Step2x2}
                        smSrc={Step2}
                      />
                    </HighlightsItem>
                  </Button>
                </HighlightsFlexItem>
              </>
            )}

            {player && player.entry && (
              <>
                <HighlightsFlexItem>
                  <Button to="/transfers" variant="home" as={Link}>
                    <HighlightsItem>
                      <TriangleCard
                        title={t("home.highlightsLeftTitle", "Pick your squad")}
                        desc={t(
                          "home.highlightsLeftDesc",
                          "Use your budget of 100M SAR to pick a squad of 15 players from RSL."
                        )}
                        mdSrc={Step1x2}
                        smSrc={Step1}
                      />
                    </HighlightsItem>
                  </Button>
                </HighlightsFlexItem>

                <HighlightsFlexItem>
                  <Button to="/leagues" variant="home" as={Link}>
                    <HighlightsItem>
                      <TriangleCard
                        title={t(
                          "home.highlightsRightTitle",
                          "Create & join leagues"
                        )}
                        desc={t(
                          "home.highlightsRightDesc",
                          "Play against friends, family members, colleagues or your friends from the web in private leagues."
                        )}
                        mdSrc={Step2x2}
                        smSrc={Step2}
                      />
                    </HighlightsItem>
                  </Button>
                </HighlightsFlexItem>
              </>
            )}

            {!player && (
              <>
                <HighlightsItem>
                  <TriangleCard
                    title={t("home.highlightsLeftTitle", "Pick your squad")}
                    desc={t(
                      "home.highlightsLeftDesc",
                      "Use your budget of 100M SAR to pick a squad of 15 players from RSL."
                    )}
                    mdSrc={Step1x2}
                    smSrc={Step1}
                  />
                </HighlightsItem>

                <HighlightsItem>
                  <TriangleCard
                    title={t(
                      "home.highlightsRightTitle",
                      "Create & join leagues"
                    )}
                    desc={t(
                      "home.highlightsRightDesc",
                      "Play against friends, family members, colleagues or your friends from the web in private leagues."
                    )}
                    mdSrc={Step2x2}
                    smSrc={Step2}
                  />
                </HighlightsItem>
              </>
            )}
          </Highlights>
        </Main>
      </Wrapper>
    );
  }
}

export { Home as HomeTest };

const mapStateToProps = (state: RootState): IPropsFromState => ({
  now: getCurrentEvent(state),
  player: getPlayerData(state),
});

export default withTranslation()(connect(mapStateToProps)(Home));
