import { Link } from "@reach/router";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState } from "../core/store";
import { getLoginError, getPlayerData } from "../core/store/player/reducers";
import { login } from "../core/store/player/thunks";
import { ILoginData } from "../core/store/player/types";
import Alert from "./Alert";
import Button from "./Button";
import Copy from "./Copy";
import { FieldWrap, InputField } from "./FieldRenderers";

const Username = styled(FieldWrap)`
  flex: 1 0 28%;
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding-inline-end: ${({ theme }) => theme.space[2]};
  }
`;

const Password = styled(FieldWrap)`
  flex: 1 0 22%;
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding-inline-end: ${({ theme }) => theme.space[2]};
  }
`;

const LoginButtonWrap = styled.div`
  margin-bottom: ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 1 0 20%;
    margin-top: 2.3rem;
    margin-bottom: ${({ theme }) => theme.space[4]};
  }
`;

const LoginForm = styled.form`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const LoginLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primary};
  margin-inline-start: ${({ theme }) => theme.space[2]};
`;

const Promo = styled.div`
  margin: ${({ theme }) => `0 ${theme.space[4]} 6rem ${theme.space[4]} `};
  padding: ${({ theme }) => theme.space[2]};
  border-radius: ${({ theme }) => theme.radii[2]};
  background-color: ${({ theme }) => theme.colors.primary};
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 3rem;
    text-align: start;
  }
`;

const PromoLeft = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 calc(70% - 1rem);
    padding-inline-end: ${({ theme }) => theme.space[4]};
  }
`;

const PromoRight = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 calc(30% - 1rem);
    align-self: center;
    > a {
      text-transform: capitalize;
    }
  }
`;

const PromoHeading = styled.h3`
  margin: 0;
  font-size: 2.1rem;
  font-family: ${({ theme }) => theme.fonts.impact};
  color: ${({ theme }) => theme.colors.green};
`;

const PromoText = styled.p`
  margin-bottom: ${({ theme }) => theme.space[4]};
  color: ${({ theme }) => theme.colors.white};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin-bottom: 0;
  }
`;

const LoginTitle = styled.h2`
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.space[4]};
  font-family: ${({ theme }) => theme.fonts.impact};
`;

const mapState = (state: RootState) => ({
  error: getLoginError(state),
  player: getPlayerData(state),
});

const mapDispatch = {
  login: (data: ILoginData) => login(data),
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Login: React.FC<PropsFromRedux> = ({ error, login, player }) => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const { t } = useTranslation();

  // TODO - Probably should send user to correct page after login
  const doLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    login({
      email,
      password,
    });
  };

  if (player && player.id) {
    return (
      <Box mb={4} p={4}>
        <Copy>
          <p>
            {t(
              "login.loggedInAs",
              "You are logged in as {{firstName}} {{lastName}}",
              {
                firstName: player.first_name,
                lastName: player.last_name,
              }
            )}
          </p>
        </Copy>
      </Box>
    );
  }

  return (
    <>
      <Box mb={4} p={4}>
        {error && (
          <div>
            <Box mb={2}>
              <Alert type="error">
                {t("login.incorrectDetails", "Incorrect login details")}
              </Alert>
            </Box>
          </div>
        )}
        <LoginTitle>{t("login.title", "Login")}</LoginTitle>
        <LoginForm onSubmit={doLogin}>
          <Username>
            <InputField
              id="loginEmail"
              name="email"
              type="email"
              label={t("login.form.emailLabel", "Email")}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </Username>
          <Password>
            <InputField
              id="loginPassword"
              name="password"
              type="password"
              label={t("login.form.pwd", "Password")}
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
          </Password>
          <LoginButtonWrap>
            <Button width={1} type="submit" disabled={!(email && password)}>
              {t("login.form.loginCta", "Login")}
            </Button>
          </LoginButtonWrap>
          <LoginLink to="/player/password-reset/">
            {t("login.form.forgotPwd", "Forgot your password?")}
          </LoginLink>
        </LoginForm>
      </Box>
      <Promo>
        <PromoLeft>
          <PromoHeading>
            {t("login.promo.heading", "Play RSL Fantasy")}
          </PromoHeading>
          <PromoText>
            {t(
              "login.promo.paragraph",
              "Who really is the best at RSL? Challenge your friends in Fantasy RSL and have the chance to win both honour and great prizes every round!"
            )}
          </PromoText>
        </PromoLeft>
        <PromoRight>
          <Button width={1} as={Link} to="/player" variant="secondary">
            {t("login.promo.signUpNow", "Sign Up Now")}
          </Button>
        </PromoRight>
      </Promo>
    </>
  );
};

export default connector(Login);
